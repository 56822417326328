<template>
  <el-dialog
      :title="params.title"
      :model-value="params.show"
      @close="onCacel"
      width="800px" center>
    <el-form :model="form" :rules="rules" ref="form" label-width="120px" class="ruleForm">
      <div style="display: grid; grid-template-columns: repeat(2, 50%);" >
      

        <el-form-item label="企业名称" prop="compName">
         <el-input v-model.trim="form.compName" placeholder="请输入企业名称"></el-input>
        </el-form-item>

        <el-form-item label="总报修次数" prop="totalRepair">
         <el-input v-model.trim="form.totalRepair" placeholder="请输入总报修次数"></el-input>
        </el-form-item>

        <el-form-item label="保守次数" prop="conservative">
         <el-input v-model.trim="form.conservative" placeholder="请输入保守次数"></el-input>
        </el-form-item>

        <el-form-item label="定检次数" prop="periodicInspection">
         <el-input v-model.trim="form.periodicInspection" placeholder="请输入定检次数"></el-input>
        </el-form-item>

        <el-form-item label="有偿次数" prop="paid">
         <el-input v-model.trim="form.paid" placeholder="请输入有偿次数"></el-input>
        </el-form-item>

        <el-form-item label="纳品次数" prop="napin">
         <el-input v-model.trim="form.napin" placeholder="请输入纳品次数"></el-input>
        </el-form-item>

        <el-form-item label="服务器报修" prop="serverRepair">
         <el-input v-model.trim="form.serverRepair" placeholder="请输入服务器报修"></el-input>
        </el-form-item>

        <el-form-item label="网络报修" prop="networkRepair">
         <el-input v-model.trim="form.networkRepair" placeholder="请输入网络报修"></el-input>
        </el-form-item>

        <el-form-item label="PC报修" prop="pcRepair">
         <el-input v-model.trim="form.pcRepair" placeholder="请输入PC报修"></el-input>
        </el-form-item>

        <el-form-item label="弱电报修" prop="weakCurrent">
         <el-input v-model.trim="form.weakCurrent" placeholder="请输入弱电报修"></el-input>
        </el-form-item>

        <el-form-item label="其他报修" prop="otherRepair">
         <el-input v-model.trim="form.otherRepair" placeholder="请输入其他报修"></el-input>
        </el-form-item>

        <el-form-item label="总移动时间" prop="totalMovingTime">
         <el-input v-model.trim="form.totalMovingTime" placeholder="请输入总移动时间"></el-input>
        </el-form-item>

        <el-form-item label="总对应时间" prop="totalCorrespondingTime">
         <el-input v-model.trim="form.totalCorrespondingTime" placeholder="请输入总对应时间"></el-input>
        </el-form-item>

      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')" >保存</el-button>
        <el-button @click="onCacel()">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import axios from '@/utils/axios'
import {ElMessage} from 'element-plus'


export default {
  name: "Form",
  // 继承父窗体列表页的数据
  props: {
    params: Object
  },
  data() {
    return {
      // 全局变量，用于获得配置信息等
      global: window.global,

      // 更多选项
      option: {
        
      },
      // 验证选项
      rules: {
        
        compName: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        totalRepair: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        conservative: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        periodicInspection: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        Paid: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        Napin: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        serverRepair: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        networkRepair: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        pCRepair: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        weakCurrent: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        otherRepair: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        totalMovingTime: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        totalCorrespondingTime: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        repairType: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        repairProject: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        correspondingTime: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
        reportDate: [
          {max: 100, message: '长度不能超过100个字符',  trigger: 'change'}
        ],
      },
    };
  },
  components: {
    
  },
  computed: {
    // 由于子组件不能修改继承来的对象，所以重新复制一份
    form: function () {
      return this.params.data
    }
  },
  created() {
    
  },
  // 父组件中继承过来的事件
  emits: ["onClose","getTableData"],
  methods: {
    
    // 提交事件
    submitForm(formName) {
      var that = this

      this.$refs[formName].validate((valid) => {

        if (valid) {

          if (this.params.type == 'add') {
            axios.post('/WorkOrderReport/Create', this.form).then(() => {
              ElMessage.success('添加成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          } else {
            axios.post('/WorkOrderReport/Modify?id='+this.form.id, this.form).then(() => {
              ElMessage.success('修改成功')
              that.$emit('onClose')
              that.$emit('getTableData')
            })
          }
        }
      })
    },
    // 返回事件
    onCacel() {
      this.$emit('onClose')
    }
  }
}
</script>